<template>
  <div class="d-flex min-vh-100">
    <div
      class="d-none d-lg-block col-6 px-0"
    >
      <div class="left-side">
        <div class="left-side__content">
          <div class="page-error page-error--404">
            <div class="left-side__logo">
              <img
                src="../../assets/images/logo/jaky-logo.svg"
                alt="логотип"
              >
            </div>
            <div class="left-side__error-code">
              404
            </div>
            <div class="left-side__illustration">
              <svg
                width="965"
                height="595"
                viewBox="0 0 965 595"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1159.72 408.468C1075.21 262.038 918.995 171.848 749.926 171.872L214.902 171.947C45.8325 171.97 -110.407 262.205 -194.962 408.658L-462.538 872.11C-547.093 1018.57 -547.119 1198.99 -462.604 1345.42L-195.158 1808.8C-110.643 1955.23 45.5697 2045.42 214.639 2045.4L749.664 2045.33C918.733 2045.3 1074.97 1955.07 1159.53 1808.61L1427.1 1345.16C1511.66 1198.7 1511.68 1018.28 1427.17 871.85L1159.72 408.468ZM286.759 1447.29C473.735 1555.25 712.848 1491.13 820.834 1304.1C928.819 1117.06 864.785 877.93 677.809 769.98C490.833 662.025 251.72 726.137 143.734 913.17C35.7484 1100.21 99.7827 1339.34 286.759 1447.29Z"
                  fill="url(#paint0_radial)"
                />
                <path
                  d="M739.192 18.202C730.635 18.202 723.674 25.164 723.674 33.721V63.113C723.674 71.882 717.248 79.175 708.858 80.538V21.857C708.858 9.80499 699.053 0 687 0C674.948 0 665.143 9.80499 665.143 21.857V105.893C656.753 104.53 650.327 97.237 650.327 88.468V59.076C650.327 50.519 643.365 43.558 634.809 43.558C626.252 43.558 619.29 50.519 619.29 59.076V88.468C619.29 114.364 639.61 135.603 665.143 137.08V172.319H656.622C655.053 172.319 653.781 173.591 653.781 175.16C653.781 176.728 655.053 178 656.622 178H717.379C718.948 178 720.219 176.728 720.219 175.16C720.219 173.591 718.948 172.319 717.379 172.319H708.858V111.724C734.39 110.248 754.711 89.008 754.711 63.112V33.72C754.711 25.164 747.749 18.202 739.192 18.202ZM749.03 63.113C749.03 86.83 729.735 106.125 706.018 106.125C704.449 106.125 703.177 107.397 703.177 108.966V172.319H670.824V134.321C670.824 132.753 669.552 131.481 667.984 131.481C644.266 131.481 624.971 112.186 624.971 88.469V59.077C624.971 53.652 629.384 49.239 634.809 49.239C640.233 49.239 644.647 53.652 644.647 59.077V88.469C644.647 100.375 653.611 110.22 665.144 111.627V116.54C665.144 118.109 666.415 119.38 667.984 119.38C669.553 119.38 670.824 118.109 670.824 116.54V21.857C670.824 12.938 678.081 5.681 687 5.681C695.92 5.681 703.177 12.938 703.177 21.857V91.184C703.177 92.752 704.449 94.024 706.017 94.024C707.586 94.024 708.858 92.752 708.858 91.184V86.271C720.39 84.864 729.355 75.018 729.355 63.112V33.72C729.355 28.296 733.768 23.883 739.192 23.883C744.617 23.883 749.03 28.296 749.03 33.72V63.113Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M687.001 151.494C685.432 151.494 684.16 152.766 684.16 154.335V160.015C684.16 161.584 685.432 162.856 687.001 162.856C688.569 162.856 689.841 161.584 689.841 160.015V154.335C689.841 152.766 688.569 151.494 687.001 151.494Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M694.656 56.8125C693.087 56.8125 691.815 58.0845 691.815 59.6535V65.3345C691.815 66.9025 693.087 68.1745 694.656 68.1745C696.225 68.1745 697.496 66.9025 697.496 65.3345V59.6535C697.496 58.0845 696.225 56.8125 694.656 56.8125Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M679.345 37.877C677.777 37.877 676.505 39.1489 676.505 40.7179V46.3989C676.505 47.9669 677.777 49.239 679.345 49.239C680.914 49.239 682.186 47.9669 682.186 46.3989V40.7179C682.186 39.1489 680.914 37.877 679.345 37.877Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M739.192 43.5576C737.623 43.5576 736.352 44.8296 736.352 46.3986V52.0786C736.352 53.6476 737.623 54.9196 739.192 54.9196C740.761 54.9196 742.032 53.6476 742.032 52.0786V46.3986C742.032 44.8296 740.761 43.5576 739.192 43.5576Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M634.808 77.6426C633.24 77.6426 631.968 78.9146 631.968 80.4826V86.1636C631.968 87.7326 633.24 89.0046 634.808 89.0046C636.377 89.0046 637.649 87.7326 637.649 86.1636V80.4826C637.649 78.9146 636.377 77.6426 634.808 77.6426Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M402.394 137.761C397.658 134.442 387.72 129.719 369.516 129.719H366.71L330.819 112.971C326.731 111.063 322.275 110.074 317.764 110.074H286.549C283.887 110.074 281.255 110.615 278.809 111.663L246.402 125.552C244.306 126.45 242.049 126.914 239.769 126.914H237.613C234.513 126.912 232 129.425 232 132.525V150.293C232 153.006 233.939 155.331 236.609 155.815L262.871 160.59V163.396H400.387C403.487 163.396 406 160.884 406 157.784V144.676C406 141.915 404.655 139.345 402.394 137.761Z"
                  fill="url(#paint1_radial)"
                />
                <path
                  d="M287.025 115.688H309.852V129.719H276.155C270.93 129.719 269.388 122.598 274.145 120.436L282.38 116.693C283.839 116.03 285.423 115.688 287.025 115.688Z"
                  fill="white"
                />
                <path
                  d="M403.356 152.172C401.156 152.172 399.247 150.846 398.249 148.885C395.004 142.509 388.387 138.139 380.741 138.139C373.098 138.139 366.48 142.509 363.234 148.885C362.237 150.846 360.328 152.172 358.128 152.172H302.16V140.946H275.309C273.96 140.946 272.602 140.719 271.39 140.127C268.813 138.868 265.933 138.139 262.871 138.139C259.805 138.139 256.919 138.864 254.341 140.126C253.127 140.719 251.765 140.946 250.414 140.946H232V150.293C232 150.623 232.031 150.945 232.086 151.26C232.141 151.57 232.223 151.872 232.327 152.165C232.328 152.167 232.328 152.169 232.328 152.172C232.986 154.021 234.586 155.448 236.609 155.816L262.871 160.591L268.484 163.397H400.387C403.487 163.397 406 160.884 406 157.785V152.172H403.356Z"
                  fill="url(#paint2_radial)"
                />
                <path
                  d="M400.387 163.396H274.097V157.784H406C406 160.883 403.487 163.396 400.387 163.396Z"
                  fill="url(#paint3_radial)"
                />
                <path
                  d="M366.71 129.719L336.64 115.688H296.327C293.894 115.688 292.581 118.543 294.165 120.391L299.641 126.78C301.241 128.645 303.576 129.72 306.034 129.72H366.71V129.719Z"
                  fill="#C3DDFF"
                />
                <path
                  d="M294.945 121.3L299.641 126.778C301.24 128.645 303.575 129.719 306.034 129.719H366.709L348.669 121.3H294.945Z"
                  fill="#A4CCFF"
                />
                <path
                  d="M302.161 140.946H294.415C292.982 140.946 292.163 142.583 293.023 143.73L297.671 149.926C298.731 151.339 300.395 152.171 302.161 152.171H309.906C311.34 152.171 312.159 150.534 311.299 149.386L306.651 143.191C305.592 141.777 303.927 140.946 302.161 140.946Z"
                  fill="#6F6571"
                />
                <path
                  d="M237.613 135.332H232V140.946H237.613C239.165 140.946 240.419 139.69 240.419 138.138C240.419 136.587 239.165 135.332 237.613 135.332Z"
                  fill="#FF9A9F"
                />
                <path
                  d="M394.775 157.784C394.775 165.534 388.492 171.816 380.742 171.816C372.993 171.816 366.71 165.534 366.71 157.784C366.71 150.034 372.993 143.752 380.742 143.752C388.492 143.752 394.775 150.034 394.775 157.784Z"
                  fill="#5D5360"
                />
                <path
                  d="M389.16 157.784C389.16 162.433 385.392 166.203 380.741 166.203C376.092 166.203 372.322 162.433 372.322 157.784C372.322 153.134 376.092 149.365 380.741 149.365C385.392 149.365 389.16 153.134 389.16 157.784Z"
                  fill="#111111"
                />
                <path
                  d="M383.547 157.785C383.547 159.334 382.291 160.591 380.741 160.591C379.192 160.591 377.935 159.334 377.935 157.785C377.935 156.234 379.192 154.979 380.741 154.979C382.291 154.979 383.547 156.234 383.547 157.785Z"
                  fill="url(#paint4_radial)"
                />
                <path
                  d="M276.904 157.784C276.904 165.534 270.622 171.816 262.872 171.816C255.122 171.816 248.84 165.534 248.84 157.784C248.84 150.034 255.122 143.752 262.872 143.752C270.622 143.752 276.904 150.034 276.904 157.784Z"
                  fill="#5D5360"
                />
                <path
                  d="M271.29 157.784C271.29 162.433 267.52 166.203 262.871 166.203C258.221 166.203 254.452 162.433 254.452 157.784C254.452 153.134 258.221 149.365 262.871 149.365C267.52 149.365 271.29 153.134 271.29 157.784Z"
                  fill="#111111"
                />
                <path
                  d="M265.678 157.785C265.678 159.334 264.421 160.591 262.872 160.591C261.321 160.591 260.065 159.334 260.065 157.785C260.065 156.234 261.321 154.979 262.872 154.979C264.421 154.979 265.678 156.234 265.678 157.785Z"
                  fill="url(#paint5_radial)"
                />
                <path
                  d="M312.499 129.719H319.888L307.859 115.688H300.472L312.499 129.719Z"
                  fill="#857E87"
                />
                <path
                  d="M305.283 121.3L312.5 129.719H319.889L312.672 121.3H305.283Z"
                  fill="#6F6571"
                />
                <path
                  d="M400.387 143.753H405.948C405.701 141.542 404.634 139.541 402.894 138.139H400.387C398.837 138.139 397.581 139.396 397.581 140.946C397.581 142.496 398.837 143.753 400.387 143.753Z"
                  fill="#FFC269"
                />
                <path
                  d="M304.875 150.622L297.617 140.946H294.415C292.982 140.946 292.163 142.583 293.023 143.73L297.671 149.926C298.731 151.339 300.395 152.171 302.161 152.171H307.973C306.755 152.171 305.606 151.596 304.875 150.622Z"
                  fill="#5D5360"
                />
                <path
                  d="M779.938 125.84C779.938 127.409 781.208 128.681 782.777 128.681H788.458C790.026 128.681 791.298 127.409 791.298 125.84C791.298 124.272 790.026 123 788.458 123H782.777C781.208 123 779.938 124.272 779.938 125.84Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M761 141.151C761 142.72 762.272 143.992 763.84 143.992H769.521C771.09 143.992 772.362 142.72 772.362 141.151C772.362 139.583 771.09 138.311 769.521 138.311H763.84C762.272 138.311 761 139.583 761 141.151Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M506.137 44.5021C506.137 46.0711 507.408 47.3431 508.977 47.3431H514.658C516.227 47.3431 517.498 46.0711 517.498 44.5021C517.498 42.9341 516.227 41.6621 514.658 41.6621H508.977C507.408 41.6621 506.137 42.9341 506.137 44.5021Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M487.2 59.8127C487.2 61.3817 488.472 62.6537 490.041 62.6537H495.721C497.29 62.6537 498.562 61.3817 498.562 59.8127C498.562 58.2447 497.29 56.9727 495.721 56.9727H490.041C488.472 56.9727 487.2 58.2447 487.2 59.8127Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M228.937 79.84C228.937 81.409 230.208 82.681 231.777 82.681H237.458C239.026 82.681 240.298 81.409 240.298 79.84C240.298 78.272 239.026 77 237.458 77H231.777C230.208 77 228.937 78.272 228.937 79.84Z"
                  fill="#9F2BE8"
                />
                <path
                  d="M210 95.1505C210 96.7195 211.272 97.9915 212.84 97.9915H218.521C220.09 97.9915 221.362 96.7195 221.362 95.1505C221.362 93.5825 220.09 92.3105 218.521 92.3105H212.84C211.272 92.3105 210 93.5825 210 95.1505Z"
                  fill="#9F2BE8"
                />
                <defs>
                  <radialGradient
                    id="paint0_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(482.283 1108.64) rotate(30) scale(936.5 1008.5)"
                  >
                    <stop stop-color="#4801FF" />
                    <stop
                      offset="0.48"
                      stop-color="#7918F2"
                    />
                    <stop
                      offset="1"
                      stop-color="#AC32E4"
                    />
                  </radialGradient>
                  <radialGradient
                    id="paint1_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(319 136.735) scale(87 26.6611)"
                  >
                    <stop stop-color="#4801FF" />
                    <stop
                      offset="0.48"
                      stop-color="#7918F2"
                    />
                    <stop
                      offset="1"
                      stop-color="#AC32E4"
                    />
                  </radialGradient>
                  <radialGradient
                    id="paint2_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(319 150.768) scale(87 12.6293)"
                  >
                    <stop stop-color="#4801FF" />
                    <stop
                      offset="0.48"
                      stop-color="#7918F2"
                    />
                    <stop
                      offset="1"
                      stop-color="#AC32E4"
                    />
                  </radialGradient>
                  <radialGradient
                    id="paint3_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(340.048 160.59) scale(65.9516 2.80637)"
                  >
                    <stop stop-color="#4801FF" />
                    <stop
                      offset="0.48"
                      stop-color="#7918F2"
                    />
                    <stop
                      offset="1"
                      stop-color="#AC32E4"
                    />
                  </radialGradient>
                  <radialGradient
                    id="paint4_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(380.741 157.785) scale(2.80637)"
                  >
                    <stop stop-color="#4801FF" />
                    <stop
                      offset="0.48"
                      stop-color="#7918F2"
                    />
                    <stop
                      offset="1"
                      stop-color="#AC32E4"
                    />
                  </radialGradient>
                  <radialGradient
                    id="paint5_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(262.872 157.785) scale(2.80637)"
                  >
                    <stop stop-color="#4801FF" />
                    <stop
                      offset="0.48"
                      stop-color="#7918F2"
                    />
                    <stop
                      offset="1"
                      stop-color="#AC32E4"
                    />
                  </radialGradient>
                </defs>
              </svg>
            </div>
            <div class="small-circle top-right-corner--small" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-12 col-lg-6 px-2 p-lg-5"
    >
      <div class="right-side">
        <b-card class="p-2 p-lg-4 w-100">
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Такой страницы нет
          </b-card-title>
          <b-card-text class="mb-2">
            Можете перейти на главную
          </b-card-text>
          <b-button
            class="w-auto"
            variant="primary"
            :to="{name: 'home'}"
          >
            На главную
          </b-button>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCardTitle, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BButton,
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
